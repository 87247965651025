import React from 'react'

import clsx from 'clsx'

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {}

const Container = ({ children, className, ...rest }: ContainerProps) => {
  return (
    <div
      {...rest}
      className={clsx(
        'container text-center md:mx-auto md:text-left ',
        className,
      )}
    >
      {children}
    </div>
  )
}

export default Container
